import { useState } from 'react'

import cx from 'classnames'
import styles from './style.module.css'
import {
  CampaignIncentive,
  EndUserCampaign,
  PartialEndUser,
} from '../../../../server/src/graphql'
import { defaultImage, displayS3File } from '../../../../client/src/utils/s3'
import Header from '../../components/header'
import { BackButton, NextButton } from '../../components/buttons'
import { TFunction } from 'i18next'
import { endUserType } from '../../utils/user-type'
import i18n from '../../../../client/src/i18n'
import { NavigateFunction } from 'react-router-dom'

type IncentiveCardProps = {
  campaignIncentive: CampaignIncentive | null
}

export default function ChooseIncentive({
  campaign,
  theme,
  endUser,
  t,
  pathname,
  navigate,
  onSubmit,
}: {
  campaign: EndUserCampaign
  theme: string
  endUser: PartialEndUser
  t: TFunction<'translation', undefined>
  pathname: string
  id?: string
  navigate: NavigateFunction
  onSubmit: (selectedIncentive?: string) => void
}) {
  const language = i18n.language
  const typeOfUser = endUserType(endUser.referral)
  const [selectedIncentive, setSelectedIncentive] = useState<
    string | undefined
  >()

  const translations =
    campaign?.ecFlowTexts?.ecFlowTexts?.[typeOfUser]?.['incentive']?.[language]
  const IncentiveCard = ({ campaignIncentive }: IncentiveCardProps) => {
    if (!campaignIncentive) return null

    if (isLottery && !isReferral) {
      setSelectedIncentive(campaignIncentive.id)
    }

    return (
      <button
        disabled={
          isLottery && !isReferral
            ? true
            : !campaignIncentive.incentiveItem.isAvailable
        }
        key={campaignIncentive.id}
        onClick={() => {
          setSelectedIncentive(campaignIncentive.id)
        }}
        className={cx(
          selectedIncentive && selectedIncentive === campaignIncentive?.id
            ? 'border border-black-1 shadow-xl'
            : 'border border-gray-100',
          styles.incentiveButton,
          'flex flex-col color-black rounded-lg bg-white hover:border-black-1 cursor-pointer shadow-xl',
        )}
      >
        <div className="block justify-between m-auto">
          <img
            className="rounded-t-md m-auto object-cover w-40 max-h-40"
            src={
              campaignIncentive.incentiveItem.picture
                ? displayS3File(campaignIncentive.incentiveItem.picture)
                : defaultImage
            }
          />
        </div>
        <p className="py-2 font-normal text-black-1 text-sm m-auto">
          {campaignIncentive.incentiveItem.name}
        </p>
      </button>
    )
  }

  const isLottery = campaign.campaignCategory === 'lottery'

  const isReferral = !!endUser.referralOf

  const Incentives = () => {
    if (!campaign.campaignIncentives) return null

    const lottaryIncentive = campaign?.campaignIncentives?.filter(
      (campaignIncentive) =>
        campaignIncentive?.incentiveItem.skuCode === endUser.sku,
    )[0]

    // in case of Lottery Campaign there is unique incentive for the user
    return isLottery && lottaryIncentive ? (
      <IncentiveCard
        campaignIncentive={lottaryIncentive}
        key={lottaryIncentive?.id}
      />
    ) : (
      campaign?.campaignIncentives?.map(
        (campaignIncentive: CampaignIncentive | null, index: number) => {
          return (
            <IncentiveCard
              key={`${campaignIncentive?.id}-${index}`}
              campaignIncentive={campaignIncentive}
            />
          )
        },
      )
    )
  }

  return (
    <div className="h-full flex flex-col justify-between">
      <Header
        title={
          translations?.header
            ? translations?.header
            : t('ec.choose_incentive.sub_title')
        }
        theme={theme}
        pathname={pathname}
      />
      <div
        className={cx(
          `theme-${theme}`,
          theme === 'theme2' ? 'bg-white' : undefined,
          'mobile:m-auto flex flex-col h-fit',
        )}
      >
        <h1 className="text-center mobile:text-2xl fold:text-xl font-medium pt-6 text-black-1 uppercase">
          {translations?.text ? (
            <p dangerouslySetInnerHTML={{ __html: translations.text }} />
          ) : (
            <>
              {isLottery && !isReferral
                ? t('ec.choose_incentive.header_lottery')
                : t('ec.choose_incentive.header')}
            </>
          )}
        </h1>
        <div
          className={cx(
            styles.contentWrapper,
            'theme-theme2:bg-white px-4 mobile:m-auto fold:m-1 grid',
            isLottery && !isReferral ? 'grid-cols-1' : 'grid-cols-2 gap-4 ',
          )}
        >
          <Incentives />
        </div>
        <div className={`theme-${theme} grid gap-4 my-4`}>
          <NextButton
            type="submit"
            onClick={() => onSubmit(selectedIncentive)}
            t={t}
          />
          <BackButton navigate={navigate} t={t} />
        </div>
      </div>
    </div>
  )
}
