import { displayS3File } from '../../../../client/src/utils/s3'
import styles from './style.module.css'
import cx from 'classnames'
import { NextButton } from '../buttons'
import { PartialEndUser, EndUserCampaign } from '../../../../server/src/graphql'
import { TFunction } from 'i18next'
import { endUserType } from '../../utils/user-type'

export default function ECHome({
  campaign,
  theme,
  endUser,
  id,
  t,
  pathname,
  navigate,
}: {
  campaign: EndUserCampaign
  theme: string
  endUser: PartialEndUser
  t: TFunction<'translation', undefined>
  pathname: string
  id?: string
  navigate: (path: string) => void
}) {
  const language = 'nl'
  const typeOfUser = endUserType(endUser.referral)

  const { logo, salutation } = campaign
  const translations =
    campaign?.ecFlowTexts?.ecFlowTexts?.[typeOfUser]?.['home']?.[language]

  const isReferral =
    endUser?.referralOf && endUser?.referralOf != endUser.campaignId
  const endUserSalutation =
    isReferral || campaign.postcardTemplate?.['type'] === 'flyer'
      ? `${t('ec.hello')},`
      : salutation?.['isFormal'] === 'Formal' && !endUser.referralOf
      ? endUser.middle
        ? `${t('ec.dear')} ${endUser?.firstName} ${
            endUser.middle
          } ${endUser?.lastName},`
        : `${t('ec.dear')} ${endUser?.firstName} ${endUser?.lastName},`
      : `${t('ec.dear')} ${endUser?.firstName},`
  return (
    <div
      className={cx(
        `${theme}`,
        pathname.includes('campaigns')
          ? 'cursor-none pointer-events-none h-full'
          : ' w-screen h-full',
        `grid justify-around bg-cover bg-${theme} text-theme-header-text h-full`,
      )}
    >
      <div
        className={cx(
          styles.contentWrapper,
          'flex flex-col items-center gap-8 text-center m-auto text-inherit max-w-[375px] h-full',
        )}
      >
        {logo && <img src={displayS3File(logo)} className="w-32" />}
        <h1 className="font-bold">{endUserSalutation}</h1>
        {isReferral ? (
          <>
            <h2>
              {translations?.header
                ? translations?.header
                : t('ec.home.header')}
            </h2>
            <p
              dangerouslySetInnerHTML={{
                __html: translations?.text
                  ? translations?.text
                  : t('ec.home.text'),
              }}
            />
          </>
        ) : (
          <>
            <h2>
              {translations?.header
                ? translations?.header
                : t('ec.home.header')}
            </h2>
            <p
              dangerouslySetInnerHTML={{
                __html: translations?.text
                  ? translations?.text
                  : t('ec.home.text'),
              }}
            />
          </>
        )}
      </div>
      <div className="grid items-end px-4 my-4 min-w-[339px] max-w-[375px] mx-auto pb-8">
        <NextButton
          className={cx(
            theme,
            'theme-theme1:bg-black-1 theme-theme1:border-black-1 theme-theme2:bg-transparent',
            'w-full border text-white rounded-md shadow-md text-[1rem]',
          )}
          label={t('ec.yes_button')}
          onClick={() => navigate(`/${id}/choose-incentive`)}
          t={t}
        />
      </div>
    </div>
  )
}
