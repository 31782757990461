import { ReactElement, useEffect } from 'react'
import useSourcelabForm, {
  FieldChange,
  Values,
  FormProps,
  renderFormElement,
  isLayout,
} from '@sourcelabbg/form/lib'
import styles from './form.module.css'
import {
  AlphaTypes,
  FileUploadInput,
  QuillEditorInput,
  RadioGroupHappinessInput,
  RadioGroupSurveyInput,
  RadioGroupWithDescriptionInput,
} from './custom-types'
import CustomInput from './custom-input'
import CustomDate from './custom-date'
import CustomTextarea from './custom-textarea'
import cx from 'classnames'
import {
  Controller,
  Resolver,
  SubmitErrorHandler,
  SubmitHandler,
} from 'react-hook-form'
import { Switch } from '../switch'
import RadioGroupStandard from './custom-radio'
import RadioGroupSurvey from './custom-radio-survey'
import CustomInputNumber from './custom-input-number'
import { CustomSelect } from './custom-select'
import '../../i18n'

import YesNoRadio from './yes-no-radio'
import RadioGroupHappiness from './custom-radio-happiness-check'
import RadioGroupType from './custom-radio-type'
import RadioGroupGender from './custom-radio-gender'
import RangeInput from './custom-range'
import { t } from 'i18next'
import RadioGroupWithDescription from './custom-radio-group-with-description'
import CustomHiddenInput from './custom-hidden-input'
import CustomFileUpload from './custom-file-upload'
import QuillEditor from './quill-editor'
import CustomDateTime from './custom-date-time'
import WYSIWYG from './custom-wysiwyg-editor'

function fieldIs<T extends AlphaTypes>(
  field: AlphaTypes,
  type: string,
): field is T {
  if (isLayout(field)) return false
  if (field.type !== 'custom') return false
  return field.customControlType === type
}

// TODO: move this to the form module
const dotNotationSearch = (
  object: Record<string, unknown>,
  path: string,
): string | undefined => {
  const pathArray = path.split('.')
  if (pathArray.length === 1)
    return (object?.[path] as undefined | { message: string })
      ?.message as string
  if (!object[pathArray[0]]) return undefined
  return dotNotationSearch(
    object[pathArray[0]] as Record<string, unknown>,
    pathArray.slice(1).join('.'),
  )
}

function useCustomRender(field: AlphaTypes, formProps: FormProps) {
  if (isLayout(field)) return <></>
  if (
    field.uiOptions?.visible &&
    !field.uiOptions?.visible(formProps.formValues)
  )
    return <></>

  const error = dotNotationSearch(formProps.errors, field.name)

  const compose = (component: ReactElement) => {
    const errorMessage = t(`validation_messages.${error}`)

    return (
      <div
        key={field.name}
        className={`${
          field.type === 'custom' && field.customControlType === 'switch'
            ? styles.switchRow
            : styles.row
        }`}
      >
        {field.uiOptions?.label && (
          <label
            className={cx(field.uiOptions.labelClassName)}
            htmlFor={field.name}
          >
            {field.uiOptions.label}
            {field.validations?.some(
              (validation) => validation === 'required',
            ) ? (
              <span className={styles.asterisk}>*</span>
            ) : (
              ''
            )}
          </label>
        )}
        {field.uiOptions?.conditionalLabel && (
          <label htmlFor={field.name}>
            {field.uiOptions.conditionalLabel(formProps.formValues)}
          </label>
        )}
        <span className={cx(field.uiOptions?.className)}>{component}</span>
        {error && (
          <span
            data-testid={`${field.name}-error`}
            className={styles.errorMessage}
          >
            {errorMessage}
          </span>
        )}
      </div>
    )
  }

  switch (field.type) {
    case 'input':
      if (field.uiOptions?.inputType === 'text') {
        return compose(<CustomInput field={field} formProps={formProps} />)
      }
      if (field.uiOptions?.inputType === 'date') {
        return compose(<CustomDate field={field} formProps={formProps} />)
      }
      if (field.uiOptions?.inputType === 'number') {
        return compose(
          <CustomInputNumber field={field} formProps={formProps} />,
        )
      }
      break
    case 'select':
      return compose(<CustomSelect field={field} formProps={formProps} />)
      break
    case 'custom':
      if (field.customControlType === 'hiddenInput') {
        return compose(
          <CustomHiddenInput
            field={{ ...field, customControlType: 'hiddenInput' }}
            formProps={formProps}
          />,
        )
      }
      if (field.customControlType === 'textarea') {
        return compose(
          <CustomTextarea
            field={{ ...field, customControlType: 'textarea' }}
            formProps={formProps}
          />,
        )
      }
      if (field.customControlType === 'quillEditor') {
        return compose(
          <QuillEditor
            field={{
              ...field,
              customControlType: 'quillEditor',
              value: typeof field.value === 'string' ? field.value : '',
              onChange: (content: string) =>
                formProps.setValue(field.name, content),
            }}
            formProps={formProps}
            maxChars={Number((field as QuillEditorInput)?.maxChars) ?? 150}
          />,
        )
      }
      if (field.customControlType === 'radioGroupStandard') {
        return compose(
          <RadioGroupStandard
            field={{ ...field, customControlType: 'radioGroupStandard' }}
            formProps={formProps}
          />,
        )
      }
      if (fieldIs<RadioGroupHappinessInput>(field, 'radioGroupHappiness')) {
        return compose(
          <RadioGroupHappiness field={field} formProps={formProps} />,
        )
      }
      if (fieldIs<RadioGroupSurveyInput>(field, 'radioGroupSurvey')) {
        return compose(<RadioGroupSurvey field={field} formProps={formProps} />)
      }
      if (field.customControlType === 'yesNoRadio') {
        return compose(
          <YesNoRadio
            field={{ ...field, customControlType: 'yesNoRadio' }}
            formProps={formProps}
          />,
        )
      }
      if (field.customControlType === 'radioGroupType') {
        return compose(
          <RadioGroupType
            field={{
              ...field,
              customControlType: 'radioGroupType',
              value: formProps.formValues?.[field.name] || '',
            }}
            formProps={formProps}
          />,
        )
      }
      if (field.customControlType === 'radioGroupGender') {
        return compose(
          <RadioGroupGender
            field={{
              ...field,
              customControlType: 'radioGroupGender',
              value: formProps.formValues[field.name] || '',
            }}
            formProps={formProps}
          />,
        )
      }
      if (field.customControlType === 'switch') {
        return compose(
          <Controller
            defaultValue={false}
            control={formProps.control}
            name={`${field.name}`}
            render={({ field }) => (
              <Switch
                onCheckedChange={field.onChange}
                checked={formProps.formValues[field.name]}
              />
            )}
          />,
        )
      }
      if (field.customControlType === 'range') {
        return compose(
          <RangeInput
            field={{
              ...field,
              customControlType: 'range',
              value: formProps.formValues[field.name] || Number(''),
            }}
            formProps={formProps}
          />,
        )
      }
      if (field.customControlType === 'fileUpload') {
        return compose(
          <CustomFileUpload
            field={{
              ...field,
              value: field.value?.toString(),
              customControlType: 'fileUpload',
              uploadUrl: (field as FileUploadInput).uploadUrl,
            }}
            formProps={formProps}
          />,
        )
      }
      if (field.customControlType === 'wysiwyg') {
        return compose(
          <WYSIWYG
            field={{
              ...field,
              value: field.value?.toString(),
              customControlType: 'wysiwyg',
            }}
            formProps={formProps}
          />,
        )
      }
      if (field.customControlType === 'dateTime') {
        return compose(
          <CustomDateTime
            field={{
              ...field,
              value: field.value as Date,
              customControlType: 'dateTime',
            }}
            formProps={formProps}
          />,
        )
      }
      if (
        fieldIs<RadioGroupWithDescriptionInput>(
          field,
          'radioGroupWithDescription',
        )
      ) {
        return compose(
          <RadioGroupWithDescription
            field={{ ...field, value: formProps.formValues[field.name] || '' }}
            formProps={formProps}
          />,
        )
      }
      break
    default:
      return compose(
        renderFormElement(
          {
            ...field,
            uiOptions: { ...field.uiOptions, className: styles.wrapper },
          },
          formProps,
        ),
      )
  }
}

export default function AlphaForm<D extends Record<string, unknown>, T>({
  fields,
  values,
  onSubmit,
  onFieldChange,
  onInvalidForm,
  resolver,
  className,
  formClassName,
  ref,
}: {
  fields: AlphaTypes[]
  values?: Values<D>
  onSubmit?: SubmitHandler<D>
  onInvalidForm?: SubmitErrorHandler<D>
  onFieldChange?: FieldChange<D>
  resolver?: Resolver<D, T>
  className?: string
  formClassName?: string
  ref?: React.RefObject<HTMLFormElement>
}) {
  const { render, onFormSubmit, reset } = useSourcelabForm({
    values: values as D,
    onSubmit,
    onFieldChange,
    customRender: useCustomRender,
    onInvalidForm,
    mode: 'onBlur',
    resetAfterSubmit: true,
    nestedClassName: styles.wrapper,
    resolver,
  })

  useEffect(() => reset(values), [reset, values])
  return (
    <div className={className}>
      <form onSubmit={onFormSubmit} className={formClassName} ref={ref}>
        {render(fields)}
      </form>
    </div>
  )
}
